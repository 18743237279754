import { graphql } from "@apollo/client/react/hoc"
import { gql } from "@apollo/client"

import { find, cloneDeep } from "lodash/fp"

import { RECRUITMENT_SYMBOLS } from "./withRecruitmentSymbols"

const RECRUITMENT_SET_SYMBOLTEXT = gql`
  mutation SymbolTextMutation($symbolTextId: String!, $text: String!, $recruitmentId: String!, $symboldId: String!) {
    upsertSymbolText(
      where: { id: $symbolTextId }
      update: { text: { set: $text } }
      create: { text: $text, recruitment: { connect: { id: $recruitmentId } }, symbol: { connect: { id: $symboldId } } }
    ) {
      id
      text
    }
  }
`

const withSetSymbolTextMutation = graphql(RECRUITMENT_SET_SYMBOLTEXT, {
  props: ({ ownProps, mutate }) => ({
    setSymbolText: ({ symbolTextId, text, recruitmentId, symboldId }) => {
      return mutate({
        variables: {
          symbolTextId: symbolTextId || null,
          text,
          recruitmentId,
          symboldId,
        },
        update: (cache, { data: { upsertSymbolText: responseData } }) => {
          const variables = { recruitmentId: recruitmentId }
          const data = cloneDeep(cache.readQuery({ query: RECRUITMENT_SYMBOLS, variables }))
          const symbol = data && find(s => s.id === symboldId)(data.recruitmentSymbols)
          if (!symbol) return
          symbol.customTextId = responseData.id
          symbol.customText = responseData.text
          symbol.text = responseData.text
          cache.writeQuery({ query: RECRUITMENT_SYMBOLS, data, variables })
        },
      })
    },
  }),
})

export default withSetSymbolTextMutation
