import React from "react"
import { withApollo } from "@apollo/client/react/hoc"
import { gql } from "@apollo/client"
import styled from "styled-components"

import { map, flatMap, filter, flow, uniqBy, reduce, orderBy, pickBy, isEmpty } from "lodash/fp"
import assign from "lodash/assign"
import debounce from "lodash/debounce"

import { Checkbox } from "_layout/form-elements"
import InlineEditor from "_components/inline-editor"
import to from "_services/await.to"

import { symbols as symbolSrc } from "_root/constants"
import { SmallSpinner } from "_layout/form-elements"

//#region Styles
const SymbolList = styled.ul`
  display: block;
  margin: 0;
  padding: 0;
  list-style-type: none;

  li {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    padding: 5px 0;
    white-space: nowrap;

    i {
      margin: 0 10px 0 0;
    }

    .inline-editor {
      flex: 1;

      .mce-content-body {
        overflow: hidden;
        text-overflow: ellipsis;

        &.mce-edit-focus {
          text-overflow: initial;
        }
      }
    }
    > .picker {
      width: 26px;
      .spinner-container {
        justify-content: left;
      }
    }

    > .end {
      width: 26px;
    }
  }
`
const Symbol = styled.i`
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: 0 5px 5px 0;

  svg {
    width: 100%;
    height: 100%;
    fill: none;
    stroke: var(--color-bg-grey);
    stroke-miterlimit: 10;
    stroke-width: 0.5px;
  }

  .blackFlag svg {
    stroke: #000000;
  }
  .redFlag svg {
    stroke: #ff0000;
  }
  .blueFlag svg {
    stroke: #0000ff;
  }
  .greenFlag svg {
    stroke: #00ff00;
  }
  .yellowFlag svg {
    stroke: #ffe600;
  }
`
//#endregion

const SelectedState = {
  SAVING_YES: "SAVING_YES",
  SAVING_NO: "SAVING_NO",
  YES: "YES",
}

const TextState = {
  SAVING: "SAVING",
  DEFAULT: null,
}

@withApollo
export default class SymbolPicker extends React.Component {
  state = {
    mounted: false,
    selectedSymbolIds: {},
    symbolsTextState: {},
  }

  static getDerivedStateFromProps = (nextProps, prevState) => {
    let nextState = {}
    const applicationsChecksum = SymbolPicker.applicationsChecksum(nextProps.applications)
    if (applicationsChecksum !== prevState.applicationsChecksum) {
      nextState.applicationsChecksum = applicationsChecksum
      nextState.selectedSymbolIds = SymbolPicker.getSelectedSymbolIds(nextProps, prevState)
    }

    let updateSymbols = !prevState.symbols
    if (!updateSymbols && nextProps.symbols) {
      if (prevState.symbols.length !== nextProps.symbols.length) {
        updateSymbols = true
      } else {
        nextProps.symbols.forEach(s1 => {
          const s2 = prevState.symbols.find(s => s.id === s1.id)
          if (s1.customText !== s2.customText) updateSymbols = true
        })
      }
    }

    if (updateSymbols) {
      nextState.symbols = nextProps.symbols
      nextState.symbolsTextState =
        nextProps.symbols && nextProps.symbols.length
          ? flow(
              map(s => s.id),
              reduce((result, id) => {
                result[id] = TextState.DEFAULT
                return result
              }, {})
            )(nextProps.symbols)
          : {}
    }
    return !isEmpty(nextState) ? nextState : null
  }

  static applicationsChecksum = applications => {
    return applications && applications.length ? map(a => a.id + "_" + map(s => s.id)(a.symbols).join(""))(applications.filter(a => a && a.id)).join("") : ""
  }

  /**
   * In order to be able to do an O(1) lookup if a symbol is selected, we use a
   * dictionary with symbol id as keys. Values:
   * SelectedState.SAVING_YES
   * SelectedState.SAVING_NO
   * SelectedState.YES
   */
  static getSelectedSymbolIds = (props, state) => {
    const savingSymbolIds = pickBy(s => s === SelectedState.SAVING_NO || s === SelectedState.SAVING_YES)(state.selectedSymbolIds)
    const applicationCount = props.applications.length
    const symbolIds = flow(flatMap("symbols"), uniqBy("id"), map("id"))(props.applications)
    const selectedSymbolIds = flow(
      filter(s => filter(a => map(as => as.id)(a.symbols).includes(s))(props.applications).length === applicationCount),
      reduce((result, id) => {
        result[id] = SelectedState.YES
        return result
      }, {})
    )(symbolIds)
    return assign(selectedSymbolIds, savingSymbolIds)
  }

  componentDidMount = () => {
    this.setState({ mounted: true })
  }

  componentWillUnmount = () => {
    if (this.props.onSymbolTextChange && this.lastSymbol && this.lastSymbol.text !== this.lastText) {
      this.props.onSymbolTextChange(this.lastSymbol, this.lastText, () => {})
    }
  }

  onSymbolChanged = async (e, symbol) => {
    if (this.state.mounted === false) return
    const checked = e.target.checked
    let newSelectedIds = {
      ...this.state.selectedSymbolIds,
      [symbol.id]: checked ? SelectedState.SAVING_YES : SelectedState.SAVING_NO,
    }
    this.props.onSelectedChangeStart && this.props.onSelectedChangeStart()
    !this.props.onSelectedChangeStart && this.setState({ selectedSymbolIds: newSelectedIds })
    const applicationSymbolsAction = checked ? "addToApplicationSymbols" : "removeFromApplicationSymbols"
    const symbols = map(application => {
      return {
        applicationsApplicationId: application.id,
        symbolsSymbolId: symbol.id,
      }
    })(this.props.applications)
    const mutation = gql`
      mutation UpdateApplicationSymbolsBatch($recruitmentId: String!, $symbols: String!, $action: String!) {
        updateApplicationSymbolsBatch(recruitmentId: $recruitmentId, symbols: $symbols, action: $action)
      }
    `
    const variables = {
      recruitmentId: this.props.recruitmentId,
      symbols: JSON.stringify(symbols),
      action: applicationSymbolsAction,
    }
    const [error, result] = await to(this.props.client.mutate({ mutation, variables }))
    if (error || !result) {
      console.error("symbol-picker:onSymbol:error", error)
    } else {
      if (checked) {
        newSelectedIds = {
          ...this.state.selectedSymbolIds,
          [symbol.id]: SelectedState.YES,
        }
      } else {
        newSelectedIds = { ...this.state.selectedSymbolIds }
        delete newSelectedIds[symbol.id]
      }
      this.setState({ selectedSymbolIds: newSelectedIds }, () => {
        this.props.onSelectedChange && this.props.onSelectedChange(symbols, applicationSymbolsAction, this.props.symbols)
      })
    }
  }

  onSymbolTextState = (symbol, state) => {
    if (!symbol || !this.state.mounted) return
    const symbolsTextState = {
      ...this.state.symbolsTextState,
      [symbol.id]: state,
    }
    this.setState({ symbolsTextState })
  }

  onSymbolTextChanged(symbol, newText) {
    this.lastSymbol = symbol
    this.lastText = newText
  }

  saveSymbolText = debounce(() => {
    if (!this.state.mounted || !this.props.onSymbolTextChange || !this.lastSymbol || !this.lastText) return
    this.onSymbolTextState(this.lastSymbol, TextState.SAVING)
    this.props.onSymbolTextChange(this.lastSymbol, this.lastText, saved => {
      if (saved) {
        this.onSymbolTextState(this.lastSymbol, TextState.DEFAULT)
      }
    })
  }, 500)

  render() {
    if (!this.state.mounted) return null
    const { selectedSymbolIds, symbolsTextState, symbols } = this.state
    return (
      <SymbolList
        style={
          this.props.maxHeight
            ? {
                maxHeight: 250,
                overflowY: "scroll",
                paddingLeft: 10,
                paddingRight: 10,
              }
            : null
        }>
        {map(symbol => (
          <li key={symbol.id}>
            <div className="picker">
              <Checkbox value={symbol.id} onChange={e => this.onSymbolChanged(e, symbol)} checked={this.state.selectedSymbolIds[symbol.id] === SelectedState.YES} />
              {/* {!selectedSymbolIds[symbol.id] || selectedSymbolIds[symbol.id] === SelectedState.YES ? (
                <Checkbox value={symbol.id} onChange={e => this.onSymbolChanged(e, symbol)} checked={this.state.selectedSymbolIds[symbol.id] === SelectedState.YES} disabled={disableSymbols} />
              ) : (
                <SmallSpinner />
              )} */}
            </div>
            <Symbol title={symbol.text}>
              <img src={symbolSrc[symbol.name]} className={symbol.name} alt="Processymbol" />
            </Symbol>
            <InlineEditor
              key={symbol.id + symbol.customText}
              text={symbol.customText || symbol.text}
              readOnly={!symbol.editable}
              onBlur={this.saveSymbolText}
              onChange={this.onSymbolTextChanged.bind(this, symbol)}
            />
            <div className="end">{symbol.editable && symbolsTextState[symbol.id] === TextState.SAVING && <SmallSpinner />}</div>
          </li>
        ))(orderBy(["order"])(["asc"])(symbols))}
      </SymbolList>
    )
  }
}
