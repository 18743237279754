import React from "react"
import styled from "styled-components"
import { Editor } from "@tinymce/tinymce-react"
import { EditIcon } from "_layout/form-elements"

//#region Styles
const Wrapper = styled.span`
  position: relative;
  min-width: 100px;
  max-width: 500px;
  padding: 2px;
  border: 1px solid ${(props) => (props.readOnly ? "transparent" : "var(--color-line)")};
  background: ${(props) => (props.readOnly ? "transparent" : "var(--color-bg-white)")};

  &:hover {
    border: 1px solid ${(props) => (props.readOnly ? "transparent" : "var(--color-line-dark)")};
  }

  .public-DraftStyleDefault-block {
    white-space: nowrap;
    margin: 0.3em;
  }

  .public-DraftEditorPlaceholder-inner {
    font-weight: 400;
    font-size: 2em;
    white-space: nowrap;
  }

  .mce-content-body {
    outline: none;
    padding: 0;

    p {
      margin: 0;
    }
  }

  .mce-edit-focus {
    outline: none !important;
  }

  .mce-tinymce {
    position: absolute;
    bottom: 100%;
    top: initial !important;
    left: -1px !important;
    z-index: 10000;
  }
`
//#endregion Styles

export default class InlineEditor extends React.Component {
  constructor(props) {
    super(props)
    const config = {
      language: "sv_SE",
      language_url: "/langs/sv_SE.js",
      inline: true,
      placeholder: (!props.readOnly && props.placeholder) || "",
      toolbar: false,
      menubar: false,
      setup: (editor) => {
        editor.on("keydown", (e) => {
          if (e.keyCode === 13) {
            e.preventDefault()
          }
        })
      },
    }

    this.state = {
      config,
      text: "",
      focus: false,
    }
  }

  static getDerivedStateFromProps = (nextProps, prevState) => {
    if (nextProps.text !== prevState.initialValue) {
      return { initialValue: nextProps.text, text: nextProps.text }
    }
    return null
  }

  componentWillUnmount = () => {
    this.mounted = false
  }

  focusEditor = () => {
    this.editor && this.editor.editor && this.editor.editor.focus()
  }

  pollChange = (editor) => {
    if (this.mounted === false) return
    const text = editor.getContent({ format: "text" }) || ""
    if (text !== this.state.text) {
      this.props.onChange && this.props.onChange(text)
      editor.hasFocus() || this.setState({ text })
    }
  }

  handleEditorChange = (_, editor) => {
    this.pollChange(editor)
  }

  render() {
    const { config, focus, initialValue } = this.state
    return (
      <Wrapper readOnly={this.props.readOnly} className="inline-editor" $focus={focus}>
        {this.props.icon && <EditIcon onClick={this.focusEditor} title="Klicka för att redigera" />}
        {this.props.readOnly && <span>{initialValue}</span>}
        {this.props.readOnly || (
          <Editor
            tinymceScriptSrc="/tinymce/tinymce.min.js"
            ref={(ref) => (this.editor = ref)}
            init={config}
            initialValue={initialValue}
            onBlur={this.props.onBlur}
            onEditorChange={this.handleEditorChange}
          />
        )}
      </Wrapper>
    )
  }
}
