import React, { PureComponent } from "react"
import styled from "styled-components"
import withRouter from "_hoc/withRouter"
import { graphql } from "@apollo/client/react/hoc"
import { compose } from "react-recompose"
import { map, flow, filter, get, pick } from "lodash/fp"
import { ButtonNavLink } from "_layout/form-elements"
import * as url from "_routes/url-names"
import AdMeter from "_components/dashboard/ad-meter"
import { RECRUITMENT_PUBLICATIONCHANNELS } from "_modules/recruitments/publicationchannel-ql"
import { PrimaryLinkButton } from "_layout/buttons"
import inject from "_services/inject"
import { UserRoles } from "_root/constants"

//#region
const CAWrapper = styled.div`
  .cta-btn {
    margin-top: 10px;
  }
`
const PublishChannelsList = styled.div``
const Title = styled.header`
  display: flex;
  align-items: baseline;
  margin-bottom: 10px;
  padding-bottom: 7px;
  border-bottom: 2px solid var(--color-brand-red);

  > span {
    display: inline-block;
    color: var(--color-text-dark);
    font-size: 21px;
    line-height: 34px;
    font-weight: 400;
  }

  .ad-meter-wrapper {
    display: inline-block;
    margin-left: auto;
  }
`
const PrimaryLink = styled(ButtonNavLink)`
  width: auto;
  margin-top: 20px;
  margin-bottom: 0;
  padding: 9px 22px;
  font-size: 0.9em;
  border-radius: 20px;
  color: var(--color-text-white);
  background: var(--color-brand-green);
`
const Career = styled.div`
  margin-bottom: 10px;
  .career-wrapper {
    display: flex;
    align-items: center;
    img {
      min-height: 35px;
      min-width: 35px;
      max-height: 35px;
    }
  }
  .ingress {
    font-size: 14px;
  }
  .order-btn {
    margin-top: 10px;
  }
`

const Search = styled.div`
  margin-bottom: 10px;
  .ingress {
    font-size: 14px;
  }
  .order-btn {
    margin-top: 10px;
  }
`

const PublishChannels = styled.div`
  margin-bottom: 20px;
`
const SubTitle = styled.header`
  display: flex;
  align-items: baseline;
  padding-bottom: 7px;

  > span {
    display: inline-block;
    color: var(--color-text-dark);
    font-size: 18px;
    font-weight: 700;
  }

  .ad-meter-wrapper {
    display: inline-block;
    margin-left: auto;
  }
`

const PublishChannelLogo = styled.img`
  opacity: 0.3;
  margin: 0 7px 7px 0;
  max-width: 35px;

  &.selected {
    opacity: 1;
  }
`

const withPublicationChannelData = graphql(RECRUITMENT_PUBLICATIONCHANNELS, {
  name: "publicationChannelsQuery",
  options: props => ({
    variables: {
      recruitmentId: props.match.params.recruitment,
    },
  }),
})
//#endregion

@compose(withRouter, inject("user"), withPublicationChannelData)
export default class CandidateAcquisitionSummary extends PureComponent {
  constructor(props) {
    super(props)
    this.searchOrdered = false
    this.props.addons.forEach(addon => {
      switch (addon.type) {
        case "SEARCH":
          this.searchOrdered = true
          break
      }
    })
  }

  hasWriteAccess = () => {
    return this.props.user && [UserRoles.GROUPVIEWER, UserRoles.VIEWER].includes(this.props.user.role) === false
  }

  render() {
    let { loading, publishChannels = [] } = this.props.publicationChannelsQuery
    const {
      match: { params },
      disableCareer,
    } = this.props

    let publishChannelsDom
    let careerPageDomLogo
    let careerPageDomText

    const editUrl = `/${params.company}${url.REKRYTERING}/${params.state}/${params.recruitment}${url.REK_KANALER}`
    const activeChannels = flow(
      filter(ch => ch.selected && ch.selected.length && get("selected[0].activated")(ch)),
      map(pick(["id", "sort", "title"]))
    )(publishChannels)

    const inactiveChannels = flow(
      filter(ch => ch.selected && ch.selected.length && !get("selected[0].activated")(ch)),
      map(pick(["id", "sort", "title"]))
    )(publishChannels)

    if (!loading) {
      publishChannelsDom = publishChannels.map(pc => {
        const active = activeChannels.some(activeChannel => activeChannel.id === pc.id)
        if (pc.id === "cklti8b370sqa0729tt7x1va8") {
          // Return false if object is Search

          careerPageDomLogo = (
            <PublishChannelLogo
              key={pc.id}
              className={active ? "selected" : ""}
              src={pc.logo && pc.logo.url && pc.logo.url}
              alt={pc.title}
              data-tooltip-id="root-tooltip"
              data-tooltip-content={`${pc.title} ${active ? "" : "- (Ej aktiverad)"}`}
            />
          )

          if (disableCareer) {
            careerPageDomText = (
              <p className="channel__description alert">
                Ni saknar just nu koppling för att publicera annonser på er egen karriärsida. Kontakta <a href="mailto:kundtjanst@jobbet.se">kundtjanst@jobbet.se</a>
              </p>
            )
          } else if (!disableCareer && !active) {
            careerPageDomText = <p className="channel__description">Annonsen är inte publicerad.</p>
          } else if (!disableCareer && active) {
            careerPageDomText = <p className="channel__description">Annonsen är publicerad</p>
          }
          return false
        }
        return (
          <PublishChannelLogo
            key={pc.id}
            className={active ? "selected" : ""}
            src={pc.logo && pc.logo.url && pc.logo.url}
            alt={pc.title}
            data-tooltip-id="root-tooltip"
            data-tooltip-content={`${pc.title} ${active ? "" : "- (Ej aktiverad)"}`}
          />
        )
      })
    }
    return (
      <CAWrapper>
        <Title>
          <span className="title">Candidate Acquisition</span>
          <div className="ad-meter-wrapper">
            <AdMeter active={this.props.adMeterScore} booked={this.props.adMeterBookedScore} />
          </div>
        </Title>
        <Career>
          <SubTitle>
            <span>Karriärssida</span>
          </SubTitle>
          <div className="career-wrapper">
            {careerPageDomLogo}
            {careerPageDomText}
          </div>
        </Career>
        <PublishChannels>
          <SubTitle>
            <span>Annonsering</span>
          </SubTitle>
          {activeChannels.length === 0 && <span className="sub-heading-missing">Ni har inga aktiverade annonskanaler för denna rekrytering</span>}
          {activeChannels.length > 0 && <span className="sub-heading">Lägg till fler kanaler för att synas mer</span>}
          <PublishChannelsList>{publishChannelsDom}</PublishChannelsList>
          {this.hasWriteAccess() && activeChannels.length > 0 && (
            <PrimaryLink className="cta-btn" to={editUrl}>
              Beställ fler annonskanaler
            </PrimaryLink>
          )}
          {this.hasWriteAccess() && activeChannels.length === 0 && (
            <PrimaryLinkButton className="edit-channels cta-btn" to={editUrl}>
              Beställ annonskanaler
            </PrimaryLinkButton>
          )}
        </PublishChannels>
        <Search>
          <SubTitle>
            <span>Search</span>
          </SubTitle>
          {!!this.searchOrdered && <div className="sub-heading">Ni har bokat Search. Jobbet.se kartlägger och tar personlig kontakt med era toppkandidater</div>}
          {this.hasWriteAccess() && !this.searchOrdered && (
            <PrimaryLinkButton className="order-btn cta-btn" onClick={() => this.props.openOrder("Search")}>
              Beställ Search
            </PrimaryLinkButton>
          )}
        </Search>
      </CAWrapper>
    )
  }
}
