import React from "react"
import { withApollo, graphql } from "@apollo/client/react/hoc"
import { compose } from "react-recompose"
import confirm from "_components/confirm"
import to from "_services/await.to"
import inject from "_services/inject"
// import { map, filter } from "lodash/fp"
import { SYSTEM_SETTINGS, PANIC_MODE } from "_root/common-ql"
// import { RECRUITMENTS_ADMETER_QUERY, RECRUITMENT_ADMETER_QUERY, RECRUITMENT_ADMETER_UPDATE } from "_modules/recruitments/publicationchannel-ql"
// import calculateScore from "_root/utils/calculateScore"
import MainLayout from "_layout/main-layout"
import { H2, FlexRow, FlexCol, Spinner } from "_layout/form-elements"
import { PrimaryButton, SecondaryButton } from "_root/layout/buttons"

@compose(withApollo, inject("user"), graphql(SYSTEM_SETTINGS, { name: "system" }), graphql(PANIC_MODE, { name: "panic" }))
export default class AdminSite extends React.Component {
  state = {
    progress: 0
  }

  togglePanic = async panicMode => {
    const { system, panic } = this.props
    const id = system.systems[0].id
    const [error, result] = await to(panic({ variables: { id: id, panic: panicMode } }))
    if (error || !result) {
      console.error("site-admin:togglePanic:error: ", error)
    } else {
      system.refetch()
    }
  }

  startPanic = async () => {
    let confirmed = await confirm("Är du säker?", { text: "Är du säker på att du vill stoppa inloggning för alla kunder och kandidater?" })
    if (!confirmed) return
    confirmed = await confirm("Är du VERKLIGEN säker?", { text: "Är du riktigt säker på att du vill stoppa inloggning för alla kunder och kandidater?" })
    if (!confirmed) return
    await this.togglePanic(true)
  }

  stopPanic = async () => {
    const confirmed = await confirm("Är du säker?", { text: "Är du säker på att du vill tillåta inloggning för alla kunder och kandidater?" })
    if (!confirmed) return
    await this.togglePanic(false)
  }

  // updateAllRecruitments = async () => {
  //   const updateAdMeter = async recruitmentId => {
  //     const {
  //       data: { recruitment },
  //     } = await this.props.client.query({ query: RECRUITMENT_ADMETER_QUERY, variables: { id: recruitmentId }, fetchPolicy: "network-only" })
  //     const adMeterScore = calculateScore({
  //       publicationChannels: map("publishChannel")(filter(pc => pc.activated)(recruitment.recruitmentSelectedPublishChannels) || []),
  //       hasOther: !!recruitment.publishChannelsOther,
  //     })
  //     const adMeterBookedScore = calculateScore({
  //       publicationChannels: map("publishChannel")(filter(pc => !pc.activated)(recruitment.recruitmentSelectedPublishChannels) || []),
  //       hasOther: false,
  //     })
  //     return this.props.client.mutate({
  //       mutation: RECRUITMENT_ADMETER_UPDATE,
  //       variables: { recruitmentId: recruitment.id, settings: { ...recruitment.settings, adMeterScore, adMeterBookedScore } },
  //     })
  //   }
  //   const {
  //     data: { recruitments },
  //   } = await this.props.client.query({ query: RECRUITMENTS_ADMETER_QUERY, fetchPolicy: "network-only" })
  //   console.warn("recruitments: ", recruitments.length)
  //   for (let i = 0; i < recruitments.length; i++) {
  //     await updateAdMeter(recruitments[i].id)
  //     this.setState({ progress: i + 1 })
  //   }
  //   console.warn("done!")
  // }

  render() {
    const {
      user,
      system: { loading, systems },
      panic,
    } = this.props

    const system = systems && systems.length && systems[0]

    return (
      <MainLayout padding maxHeight scroll>
        <div>
          <H2>Administrera webbplats</H2>
          <FlexRow className="margin20">
            <FlexCol>
              {loading && <Spinner />}
              {system && !system.panic && (
                <PrimaryButton onClick={this.startPanic} loading={panic.loading}>
                  Stoppa inloggning för kunder &amp; kandidater
                </PrimaryButton>
              )}
              {system && system.panic && (
                <SecondaryButton onClick={this.stopPanic} loading={panic.loading}>
                  Starta inloggning för kunder &amp; kandidater
                </SecondaryButton>
              )}
              {/* {user && user.email === "josef.henryson@phosworks.com" &&
              <SecondaryButton className="mt3" onClick={this.updateAllRecruitments}>Annonsmätare rekryteringar: {this.state.progress || 0}</SecondaryButton>
              } */}
            </FlexCol>
          </FlexRow>
        </div>
      </MainLayout>
    )
  }
}
