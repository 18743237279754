//#region Imports
import React from "react"
import { NavLink } from "react-router-dom"
import withRouter from "_hoc/withRouter"
import { withApollo, graphql } from "@apollo/client/react/hoc"
import { gql } from "@apollo/client"
import { compose } from "react-recompose"
import { Tooltip } from "react-tooltip"
import styled from "styled-components"
import { format, parseISO, differenceInDays, isValid } from "date-fns"
import { filter, find, isEmpty, map, remove, orderBy, cloneDeep } from "lodash/fp"
import cuid from "cuid"
import message from "_components/message"
import confirm from "_components/confirm"
import Modal from "_components/modal"
import Loader from "_components/loader"
import DropDown from "_components/drop-down"
import ApplicationMailing from "_components/applications/application-mailing"
import ApplicationInterviews from "_components/applications/application-interviews"
import ApplicationSharing from "_components/applications/application-sharing"
import ApplicationMessageLog from "_components/applications/application-messagelog"
import FilterQuestion from "_components/applications/filter-question"
import SymbolPicker from "_components/applications/symbol-picker"
import SymbolSorter from "_components/applications/symbol-sorter"
import { Checkbox, Grade, Spinner, ButtonLink, SmallSpinner, TextArea } from "_layout/form-elements"
import { PrimaryButton, SecondaryButton, CancelButton } from "_layout/buttons"
import { ProcessType, AnswerType, ProcessSubType, Process, ProcessTitlesList, TemplateType, RecruitmentState, HAS_SUBSCRIPTION, NO_SUBSCRIPTION, REC_STATS_ID } from "_root/constants"
import SubtypeToggle from "_components/applications/subtype-toggle"
import NotesInput from "_components/applications/notes-input"
import NotesIcon from "_images/notes.svg"
import NotesDarkIcon from "_images/notes-dark.svg"
import MenuIconSrc from "_images/menu-dots.svg"
import { REK_APPLICATION, JOBB, INTERVIEW } from "_routes/url-names"
import { USER_INFO, UPDATE_USER_INFO } from "_root/common-ql"
import { COMPANY_QUERY } from "_containers/recruitment/recruitment-docs-ql"
import { APPLICATIONS_QUERY, REPAIR_APPLICATION } from "_containers/recruitment/application-ql"
import { UPDATE_INTERVIEW_STATUS_SERVER } from "_containers/interview/interview-ql"
import { isLive } from "_services/util"
import * as url from "_routes/url-names"
import to from "_services/await.to"
import inject from "_services/inject"
import CheckIconSrc from "_images/check.svg"
import HourglassSrc from "_images/hourglass.svg"
import ProfileImage from "_images/profile.svg"
import MailIconSrc from "_images/email.svg"
import GradeList from "_components/applications/grade-list"
import { symbols as icons, UserRoles } from "_root/constants"
import withRecruitmentSymbols from "_modules/recruitments/hoc/withRecruitmentSymbols"
import withSetSymbolTextMutation from "_modules/recruitments/hoc/withSetSymbolTextMutation"
import CandidateAcquisitionSummary from "_modules/recruitments"
import { ServicesSummary } from "_modules/recruitments"
import { SearchSummary } from "_modules/recruitments"
import { withUpdateQueryOnBroadcast, withUpdateCacheOnBroadcast } from "_root/apollo-client/links/window-broadcast-link/"
import { getBookedServiceMailContent } from "_modules/recruitments/services/mailtemplate-service"
import withSendmailMutation from "_hoc/withSendmailMutation"
//#endregion Imports

//#region Styles
const Content = styled.div`
  flex: 1;
  padding: 0;
  background: var(--color-bg-white);

  &.block-user-input {
    opacity: 0.2;
    pointer-events: none;
  }
`
const PrimaryLink = styled(ButtonLink)`
  width: auto;
  margin-top: 0px;
  margin-bottom: 0;
  padding: 6px 18px;
  font-size: 0.9em;
  border-radius: 18px;
  color: var(--color-text-white);
  background: var(--color-brand-green);
`

const Arrow = styled.div`
  display: none;
  width: 8px;
  height: 8px;
  margin-top: -4px;
  margin-left: auto;
  background-color: var(--color-bg-white);
  border-bottom: 2px solid var(--color-line-dark);
  border-right: 2px solid var(--color-line-dark);

  &.asc {
    display: inline-block;
    transform: rotate(45deg);
  }

  &.desc {
    display: inline-block;
    margin-top: 4px;
    transform: rotate(225deg);
  }
`
const Intro = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: stretch;
  padding: 25px 40px;
  background: var(--color-bg-lighter);

  @media screen and (max-width: 1180px) {
    flex-flow: row wrap;
    .break-flex {
      flex-basis: 100%;
      height: 0;
    }
  }

  @media screen and (max-width: 767px) {
    padding: 20px 20px;
    flex-direction: column;
  }

  &.side-menu-open {
    @media screen and (max-width: 1010px) {
      padding: 20px 20px;
      flex-direction: column;
    }
  }
`
const IntroBox = styled.div`
  flex: ${props => props.$flex || ""};
  min-width: ${props => (props.$minWidth ? props.$minWidth : "auto")};
  max-width: 400px;
  width: 320px;
  min-height: 300px;
  padding: 20px 30px;
  color: var(--color-text);
  background: var(--color-bg-white);
  box-shadow: 0px 2px 20px var(--color-bg-light);

  &.position-container {
    display: flex;
    min-width: 310px;
    flex-flow: column nowrap;
    color: var(--color-text-dark);
    padding: 0px 30px 0px 30px;
    margin: 0 20px 20px 0;
    background: transparent;
    box-shadow: none;

    .subpart {
      background: var(--color-bg-white);
      box-shadow: 0px 2px 20px var(--color-bg-light);
      padding-top: 20px;
      padding-bottom: 20px;
      min-height: 197px;
      flex-grow: 1;

      &.subpart-top {
        margin-bottom: 20px;
      }
    }
    .contacts-container {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: stretch;
    }

    .contacts {
      flex: 1 0 auto;
      align-items: flex-start;
      flex-flow: column nowrap;
      max-width: 100%;
      text-align: center;
    }

    .counts-container {
      background-color: var(--color-bg-lighter);
      margin: 0 0px;
      padding: 10px;

      > a,
      > div {
        width: 100%;
      }
      > a {
        max-width: 250px;
        text-align: center;
      }
    }

    .divider {
      border-top: 1px solid var(--color-line);
      padding-top: 6px;
      padding-bottom: 4px;
      &.no-border {
        border-top: 0px;
      }
    }

    > div {
      align-items: center;
      min-height: 40px;
      margin: 0 -30px;
      padding: 0 30px;

      .small {
        margin-right: 10px;
        color: var(--color-text);
        font-size: 13px;
        line-height: 16px;
        text-transform: uppercase;
      }

      .fill {
        margin: 5px 0;
        word-wrap: break-word;
        display: inline-block;
        font-size: 15px;
        line-height: 16px;
      }
      .date {
        font-size: 15px;
        padding: 10px 0;
      }
      .highvis {
        color: var(--color-brand-red);
      }

      span.error {
        color: var(--color-error);
      }

      & span:last-child {
        flex: 1;
      }
    }
    .cta-alone {
      width: 100%;
      text-align: center;
    }
  }

  &.channel-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 20px 20px 0;
  }

  &.service-container {
    margin: 0 0 20px 0;
  }

  .sub-heading {
    display: block;
    margin-bottom: 15px;
    color: var(--color-text-dark);
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
  }

  .sub-heading-missing {
    display: block;
    margin-bottom: 15px;
    color: var(--color-brand-red);
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
  }

  @media screen and (max-width: 1180px) {
    max-width: none;
    &.position-container {
      margin-right: 0;
    }
  }

  @media screen and (max-width: 767px) {
    width: 100%;
    min-height: unset;
  }

  &.side-menu-open {
    @media screen and (max-width: 1010px) {
      width: 100%;
      min-height: unset;
    }
  }
`
const Title = styled.header`
  display: flex;
  align-items: baseline;
  margin-bottom: 10px;
  padding-bottom: 7px;
  border-bottom: 2px solid var(--color-brand-red);

  > span {
    display: inline-block;
    color: var(--color-text-dark);
    font-size: 21px;
    line-height: 34px;
    font-weight: 400;
  }
  .right {
    margin-left: auto;
  }
`

const Contacts = styled.div`
  white-space: nowrap;
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
`
const Contact = styled.div`
  display: inline-flex;
  margin: 0 10px;
  text-align: center;
  align-items: center;
  flex-flow: column nowrap;
`
const ContactImage = styled.div`
  display: inline-block;
  width: 80px;
  height: 80px;
  margin-bottom: 5px;
  border: 1px solid var(--color-text);
  background: url(${ProfileImage}) no-repeat scroll 50% 50%;
  background-size: 34px 34px;

  img {
    max-width: 100%;
  }
`
const ContactLink = styled.a`
  display: inline-block;
  font-size: 14px;
  color: var(--color-text);
  white-space: nowrap;
`

const MailIcon = styled.i`
  display: inline-block;
  margin-right: 4px;
  width: 18px;
  height: 10px;
  background: url(${MailIconSrc}) no-repeat scroll 50% 50%;
`
const DateAndInfo = styled.div`
  font-size: 17px;
  text-transform: uppercase;
  min-width: 140px;
  font-weight: 200;
  display: flex;

  .info {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    align-items: center;
    width: 50%;
  }

  .count {
    font-size: 23px;
  }
  .v-divider {
    width: 1px;
    height: 65px;
    background-color: #bbb;
    margin: 0 15px;
  }
  @media screen and (max-width: 1380px) {
    font-size: 15px;
  }
`

const Counters = styled.div`
  display: flex;
  flex-flow: column nowrap;

  @media screen and (max-width: 767px) {
    column-gap: 10px;
    flex-direction: row;

    > div {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`
const Count = styled.div`
  text-align: center;
  font-size: 2rem;
  font-weight: 200;

  @media screen and (max-width: 767px) {
    font-size: 1.5rem;
  }
`
const CountLabel = styled.div`
  text-align: center;
  font-weight: 200;
  text-transform: uppercase;

  @media screen and (max-width: 767px) {
    font-size: 0.8rem;
  }
`
const Separator = styled.div`
  width: 90%;
  border-bottom: 2px solid var(--color-bg-light);
  margin: 12px 0;

  @media screen and (max-width: 767px) {
    width: unset;
    border-bottom: unset;
    border-left: 2px solid var(--color-bg-light);
    margin: 0 12px;
  }
`
const Actions = styled.div`
  display: flex;
  column-gap: 5px;
  row-gap: 5px;
  flex-flow: row nowrap;
  justify-content: stretch;
  align-items: stretch;
  margin-bottom: 20px;

  @media screen and (max-width: 767px) {
    flex-direction: column;
  }
`
const MultipleBoxWrapper = styled.div`
  order: 1;
  position: relative;
  display: flex;
  flex: 2;
  max-height: 400px;
  opacity: 1;
  transition: all 0.5s ease-out;

  &.hidden {
    max-height: 0;
    opacity: 0;
    transition: all 0.5s ease-out;
  }

  &:before {
    position: absolute;
    top: 100%;
    left: 20px;
    content: "";
    display: block;
    width: 16px;
    height: 16px;
    background: var(--color-bg-bright);
    transform: rotate(-45deg) translate3d(0, -10px, 0);
  }

  @media screen and (max-width: 767px) {
    order: 2;
    flex-direction: column;
  }
`
const MultipleBox = styled.div`
  flex: 2 1 auto;
  position: relative;
  padding: 26px;
  background: var(--color-bg-bright);

  &:after {
    content: "";
    position: absolute;
    right: 0;
    top: 20px;
    bottom: 20px;
    width: 1px;
    border-right: 1px solid var(--color-line-light);
  }

  .drop-down {
    max-width: 175px;
    margin-bottom: 8px;
  }

  @media screen and (max-width: 767px) {
    padding: 20px;
    &:after {
      content: unset;
    }
  }
`
const ButtonBox = styled.div`
  display: flex;
  min-width: ${props => (props.$minWidth ? props.$minWidth : "auto")};
  padding: 50px 26px 26px 26px;
  background: var(--color-bg-bright);
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  flex: 1 1 auto;

  button {
    margin-right: 1rem;
    border-radius: 20px;
  }

  @media screen and (max-width: 767px) {
    padding: 0 20px;

    button {
      margin-bottom: 10px;
    }
  }
`
const SelectionBox = styled.div`
  order: 2;
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 125px;
  padding: 30px;
  background: var(--color-bg-bright);
  box-shadow: 0px 0px 10px var(--color-bg-light);

  .reset-link {
    position: absolute;
    top: 20px;
    right: 30px;
  }

  @media screen and (max-width: 767px) {
    order: 1;
    padding: 20px;

    .reset-link {
      position: unset;
      top: unset;
      right: unset;
      margin-top: 1rem;
    }
  }
`
const BoxTitle = styled.h4`
  align-self: flex-start;
  margin-top: 0;
  margin-bottom: 0.5em;
  font-weight: 400;
  text-transform: uppercase;

  @media screen and (max-width: 767px) {
    font-size: 0.9rem;
  }
`
const MultipleGradeList = styled.ul`
  margin: 0;
  padding: 0 10px 0 0;
  list-style-type: none;

  li {
    display: block;
    white-space: nowrap;
  }
`
const ApplicationActions = styled.div`
  position: absolute;
  top: 50%;
  right: 0;
  height: 16px;
  transform: translate(0, -6px);

  i {
    vertical-align: top;
  }
`
const ActionSymbol = styled.i`
  cursor: pointer;
  display: inline-block;
  margin-right: 8px;
  width: 20px;
  height: 12px;

  img,
  svg {
    max-width: 100%;
    vertical-align: top;
  }
`
const CheckIcon = styled.i`
  display: inline-block;
  margin-right: 8px;
  width: 12px;
  height: 12px;
  background: url(${CheckIconSrc}) no-repeat scroll 50% 50%;
`
const Hourglass = styled.i`
  display: inline-block;
  margin-right: 8px;
  width: 12px;
  height: 12px;
  background: url(${HourglassSrc}) no-repeat scroll 50% 50%;
`
const StatusList = styled.ul`
  margin: 0;
  padding: 0;
  list-style-type: none;

  li {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    padding: 5px 0;
    white-space: nowrap;
  }
`
const Selection = styled.ol`
  padding: 0 1em 0 1em;
  list-style-type: none;

  li {
    h4 {
      margin-top: 0;
      margin-bottom: 0.5em;
      white-space: nowrap;
    }

    > div {
      margin-bottom: 1em;
      white-space: nowrap;
    }
  }

  @media screen and (max-width: 767px) {
    li {
      h4 {
        white-space: normal;
      }
    }
  }
`
const Wrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  flex: 1;
  padding: 20px 40px 40px 40px;
  background: var(--color-bg-light);

  .heading {
    display: inline-block;
    margin-bottom: 20px;
    font-size: 18px;
    line-height: 24px;
    font-weight: 400;
  }

  @media screen and (max-width: 767px) {
    padding: 20px;
  }
`
const Rows = styled.div`
  flex: 1;
  margin: 0;

  .symbolPicker {
    pointer-events: auto;
    padding: 5px 0;
    box-shadow: 1px 1px 3px var(--color-line-dark);

    &.type-light.border {
      border: 1px solid var(--color-line-dark);

      &.place-bottom:before {
        border-bottom-color: var(--color-line-dark);
      }
      &.place-top:before {
        border-top-color: var(--color-line-dark);
      }

      &.show {
        opacity: 1;
        visibility: visible;
      }
    }

    &:hover {
      opacity: 1;
      visibility: visible;
    }
  }

  &.loading {
    .content {
      pointer-events: none;
      opacity: 0.5;
    }
  }
`
const Row = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: stretch;
  height: 56px;

  &.header {
    height: 35px;
    background: var(--color-bg-white);
    color: var(--color-text-dark);
    box-shadow: 0px 3px 5px var(--color-bg-grey);
    z-index: 1;
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    cursor: pointer;
  }

  @media screen and (max-width: 767px) {
    height: auto;
    margin-bottom: 10px;
  }
`
const SortMobile = styled.div`
  margin-left: auto;

  span {
    text-decoration: underline;
  }
`
const TitleCell = styled.div`
  position: relative;
  flex: 1;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
  padding: 5px 15px 5px 10px;
  color: var(--color-text-dark);
  background: var(--color-bg-lighter);
  border-bottom: 1px solid var(--color-line-light);

  .header & {
    border: none;
    background: var(--color-bg-white);

    &:hover {
      background: var(--color-bg-light);

      ${Arrow} {
        background-color: var(--color-bg-light);
      }
    }
  }

  @media screen and (max-width: 767px) {
    background: var(--color-bg-white);

    .header &,
    .content & {
      &:hover {
        background: var(--color-bg-white);
      }
    }
  }
`
const TitleSpan = styled.span`
  font-style: italic;
  color: var(--color-text-medium);
`
const TitleLink = styled.a`
  color: var(--color-brand-green);
  font-weight: 700;
  line-height: 1.25;

  &.expired {
    color: var(--color-text);
    text-decoration: line-through;
  }

  &:hover {
    text-decoration: underline;
  }
`
const InfoArea = styled.div`
  flex: 2;
  display: flex;
  flex-flow: row nowrap;
  justify-content: stretch;
`
const ProcessArea = styled.div`
  flex: 2;
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
  align-items: stretch;
  background: var(--color-bg-white);

  .header & {
    cursor: pointer;

    &:hover {
      background: var(--color-bg-light);

      div {
        background: var(--color-bg-light);
      }
    }
  }

  .c-processcell {
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    background: var(--color-bg-white);

    &:hover {
      background: var(--color-bg-lighter);
    }

    &.viewer {
      cursor: default;
    }

    .content & {
      width: 100%;
      border-bottom: 1px solid var(--color-line-light);

      &:before {
        content: "";
        display: block;
        position: absolute;
        top: 50%;
        margin-top: -1px;
        border-top: 2px solid var(--color-bg-light);
        z-index: 100;
      }

      &.start:before {
        left: 50%;
        right: 0;
      }

      &.both:before {
        left: 0;
        right: 0;
      }

      &.end {
        border-right: 1px solid var(--color-bg-lighter);
      }

      &.end:before {
        left: 0;
        right: 50%;
      }

      &:after {
        content: "";
        display: block;
        width: 16px;
        height: 16px;
        border-radius: 8px;
        border: 2px solid var(--color-brand-green);
        background: var(--color-bg-white);
        z-index: 200;
      }

      &.started:after {
        border: 1px solid var(--color-brand-green);
        background: var(--color-brand-green);
      }

      &.na:after {
        border: 2px dotted var(--color-bg-grey-dark);
        background: var(--color-bg-white);
      }
    }
  }

  .spinner-container {
    border-bottom: 1px solid #f5f5f5;
    border-right: 1px solid #f5f5f5;
    flex: inherit;
  }
`
const DropCell = styled.div`
  position: relative;
  display: flex;
  flex: 2;
  flex-direction: row;
  padding: 0 10px;

  .drop-down {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    button {
      font-size: 12px;
      line-height: 16px;
      font-weight: 400;
      padding-bottom: 9px;
      border: none;
      box-shadow: none;

      &:hover {
        background-color: var(--color-bg-light);
      }
    }

    > div {
      padding: 0;
    }
  }
`
const InfoCell = styled.div`
  position: relative;
  flex: ${props => props.$flex || "1"};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: ${props => (props.$left ? "flex-start" : "center")};
  border-bottom: 1px solid var(--color-line-light);
  border-left: 1px solid var(--color-line-light);
  background: var(--color-bg-white);
  white-space: nowrap;

  ${Row}.header & {
    border: none;
  }

  &:last-child {
    border-right: 1px solid var(--color-line-light);
  }

  &.date {
    color: var(--color-text);
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
  }

  &.grade {
    .grade-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 32px;
      height: 32px;
      border-radius: 50%;
      color: var(--color-text);
      background: var(--color-bg-light);
      font-size: 18px;
      line-height: 24px;
      font-weight: 200;
    }
  }

  & .drop-down {
    visibility: hidden;
    position: absolute;
    top: 50%;
    right: 5px;
    margin-top: -16px;

    &.open {
      visibility: visible;
    }
  }

  &:hover {
    .drop-down {
      visibility: visible;
    }
  }

  .header & {
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    cursor: pointer;
    padding: 0 10px;

    &:hover {
      background: var(--color-bg-light);

      ${Arrow} {
        background: var(--color-bg-light);
      }
    }
  }

  .content & {
    padding: 5px 10px;
  }
`
const Menu = styled.span`
  opacity: 0;
  visibility: hidden;
  transition: visibility 0s linear 0s, opacity 0s linear 0s;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 15px;
  right: -13px;
  bottom: 50%;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: url(${MenuIconSrc}) scroll 50% 50% no-repeat var(--color-bg-white);
  background-size: 15px 15px;
  box-shadow: 0px 0px 10px var(--color-text-medium);
  z-index: 500;

  ${InfoCell}:hover & {
    opacity: 1;
    visibility: visible;
    transition: visibility 0s linear 0s, opacity 350ms linear;
  }
`
const ProcessCell = styled.div`
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  background: var(--color-bg-white);

  &:hover {
    background: var(--color-bg-lighter);
  }

  .content & {
    width: 100%;
    border-bottom: 1px solid var(--color-line-light);

    &:before {
      content: "";
      display: block;
      position: absolute;
      top: 50%;
      margin-top: -1px;
      border-top: 2px solid var(--color-bg-light);
      z-index: 100;
    }

    &.start:before {
      left: 50%;
      right: 0;
    }

    &.both:before {
      left: 0;
      right: 0;
    }

    &.end {
      border-right: 1px solid var(--color-line-light);
    }

    &.end:before {
      left: 0;
      right: 50%;
    }

    &:after {
      content: "";
      display: block;
      width: 16px;
      height: 16px;
      border-radius: 8px;
      border: 2px solid var(--color-brand-green);
      background: var(--color-bg-white);
      z-index: 200;
    }

    &.started:after {
      border: 1px solid var(--color-brand-green);
      background: var(--color-brand-green);
    }

    &.na:after {
      border: 2px dotted var(--color-bg-grey-dark);
      background: var(--color-bg-white);
    }
  }
`
const Notes = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  border-bottom: 1px solid var(--color-line-light);
  background: var(--color-bg-white);

  i {
    width: 17px;
    height: 20px;
    background: url(${props => (props.$active ? NotesDarkIcon : NotesIcon)}) scroll no-repeat transparent;

    &:hover {
      opacity: 0.5;
    }
  }

  :hover {
    background: var(--color-bg-light);
  }
`
const Narrow = styled.div`
  position: relative;
  padding: 5px 10px;
  width: 100%;
  font-size: 0.8rem;
  background-color: var(--color-bg-white);

  label {
    margin-right: 0.5rem;
    font-weight: 700;
  }

  .symbols {
    position: absolute;
    top: 5px;
    right: 5px;
  }
`

const ModalHeader = styled.header`
  font-size: 1.5rem;
  color: var(--color-brand-green);
  margin-bottom: 1rem;
  font-weight: bold;
`
const AddonList = styled.ul`
  flex: 1;
  margin-bottom: 0;
  padding: 0 16px;
  list-style-type: none;

  li {
    position: relative;
    margin-bottom: 10px;
    padding-left: 18px;
    font-size: 14px;

    &:before {
      content: "✓";
      font-size: 16px;
      display: inline-block;
      position: absolute;
      top: 0;
      left: 0;
    }

    &.added {
      &:before {
        content: "+";
      }
      color: var(--color-brand-red);
    }

    &.removed {
      &:before {
        content: "–";
      }
      color: var(--color-brand-red);
      text-decoration: line-through;
    }
  }
`
const Nowrap = styled.div`
  text-align: center;
  white-space: nowrap;
`
//#endregion

let sortProp = "createdAt"
let sortOrder = { name: "", grade: "", createdAt: "desc", symbols: "", process: "" }
@compose(
  withRouter,
  withApollo,
  inject("user", "menu"),
  withSendmailMutation,
  graphql(UPDATE_USER_INFO, { name: "updateUserInfo" }),
  graphql(REPAIR_APPLICATION, { name: "repairApplication" }),
  graphql(UPDATE_INTERVIEW_STATUS_SERVER, { name: "updateInterviewStatus" }),
  graphql(COMPANY_QUERY, { name: "companyQuery", options: props => ({ variables: { urlName: props.recruitment.company.urlName } }) }),
  graphql(APPLICATIONS_QUERY, {
    name: "applicationsQuery",
    options: props => ({ fetchPolicy: "network-only", notifyOnNetworkStatusChange: true, variables: { recruitmentId: props.match.params.recruitment } }),
  }),
  withRecruitmentSymbols(),
  withUpdateQueryOnBroadcast({
    applicationsQuery: ["UpdateApplication", "UpdateApplicationSymbolsBatch", "UpdateProcessStep", "SymbolTextMutation", "DeleteApplications"], // Update applicationQuery above when any UpdateApplication mutation happens in childwindow.
    recruitmentSymbolsQuery: ["SymbolTextMutation"],
  }),
  withUpdateCacheOnBroadcast({
    RecruitmentStats: ["UpdateApplication", "UpdateProcessStep"], // Update recruitment cache when any UpdateApplication or UpdateProcessStep mutation happens in childwindow.
    recruitmentSymbolsQuery: ["SymbolTextMutation"],
  }),
  withSetSymbolTextMutation
)
export class ApplicationListClass extends React.Component {
  constructor(props) {
    super(props)
    this.apps = []
    this.selectionFilter = {}
    let recruitmentProcessSteps = filter(s => s.order > 0)(props.recruitment.process)
    recruitmentProcessSteps = orderBy(p => p.order)(["asc"])(recruitmentProcessSteps)
    this.processNames = []
    recruitmentProcessSteps.forEach(step => {
      this.processNames.push(ProcessTitlesList[step.order])
    })

    this.state = {
      mobile: window.innerWidth < 768,
      showConfirmOrderModal: false,
      newAddon: "",
      loading: false,
      queryInit: true,
      queryLoading: true,
      applications: [],
      filtered: [],
      selected: [],
      allSelected: false,
      gradeSelect: false,
      symbolSelect: false,
      statusSelect: false,
      filterSelect: false,
      symbolSorting: false,
      symbolSortingId: null,
      recruitmentProcessSteps,
      gradesToggle: {},
      symbolsToggle: {},
      singleGrade: null,
      currentSort: "datum",
      company: { jobbetContacts: [] },
      emailTemplates: [],
      recipients: [],
      processSaving: {},
      // notesSaving: {},
      notesKey: Math.ceil(Math.random() * 10),
      orderMessage: "",
      symbolsKey: Math.ceil(Math.random() * 10),
    }
    const mql = window.matchMedia("(max-width: 767px)")
    mql.addEventListener("change", e => {
      this.setState({ mobile: e.currentTarget.matches })
    })
  }

  openOrderModular = addonType => {
    this.setState({ newAddon: addonType, showConfirmOrderModal: true })
  }

  dismissOrderModal = () => {
    this.setState({ showConfirmOrderModal: false, orderMessage: "" })
  }

  onOrderMessageChange = e => {
    this.setState({ orderMessage: e.currentTarget.value })
  }

  handleOrder = async () => {
    const { newAddon, orderMessage } = this.state
    const { sendMail, user } = this.props
    const { title } = this.props.recruitment
    const jobTitle = title || "Ej skapad"
    const [error] = await to(
      sendMail({
        from: "noreply@jobbet.se",
        to: process.env.REACT_APP_AD_SUPPORT,
        subject: `Beställning - rekryteringstjänster`,
        html: getBookedServiceMailContent({ sender: user.fullName, company: user.employer.name, job: jobTitle, order: newAddon, orderMessage }),
        showFooter: false,
      })
    )
    if (!error) {
      await message("Din beställning har skickats!")
      this.setState({
        showConfirmOrderModal: false,
        orderMessage: "",
      })
    } else {
      // Kontakta kundtjänst.
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const nextState = {}
    if (nextProps.recruitment) {
      nextState.recruitment = nextProps.recruitment
    }
    nextState.queryLoading = nextProps.applicationsQuery ? nextProps.applicationsQuery.loading : true
    nextState.queryInit = !prevState.queryInit ? false : nextState.queryLoading
    if (prevState.queryLoading && !nextState.queryLoading && nextProps.applicationsQuery.applications) {
      let applications = nextProps.applicationsQuery.applications.map(a => {
        if (a.candidate && a.candidate.user) return a
        return { ...a, candidate: { user: { firstName: "Utgången", lastName: "ansökan" }, expired: true } }
      })
      applications = applications.filter(a => a && a.id)
      nextState.applications = ApplicationListClass.getSorted(applications, prevState.symbolSortingId)
      nextState.filtered = prevState.filtered.length ? filter(a => a && !!find(f => f.id === a.id)(prevState.filtered))(nextState.applications) : [...nextState.applications]
      nextState.selected = prevState.selected.length ? filter(a => a && !!find(s => s.id === a.id)(prevState.selected))(nextState.applications) : []
      ApplicationListClass.updateUserInfo(nextProps, applications.length)
    }
    if (nextProps.companyQuery && !nextProps.companyQuery.loading && nextProps.companyQuery.company) {
      nextState.emailTemplates = filter(t => t.type === TemplateType.APPLICANT_EMAIL)(nextProps.companyQuery.company.templates)
      nextState.company = nextProps.companyQuery.company
    }
    return !isEmpty(nextState) ? nextState : null
  }

  static async updateUserInfo(props, count) {
    const { user, recruitment } = props
    if (!recruitment || !recruitment.id) return
    const [errorGet, resultGet] = await to(props.client.query({ query: USER_INFO, fetchPolicy: "network-only", variables: { id: user.id } }))
    if (errorGet || !resultGet || !resultGet.data || !resultGet.data.user) {
      console.error("application-list:updateUserInfo:error:", errorGet, user.id, resultGet)
      return
    }
    // Deep copy object
    const userInfo = JSON.parse(JSON.stringify(resultGet.data.user.info || {}))
    userInfo.appCount = userInfo.appCount || {}
    if (userInfo.appCount[recruitment.id] === count) return
    userInfo.appCount[recruitment.id] = count
    const [errorSet, resultSet] = await to(props.updateUserInfo({ variables: { id: user.id, userInfo } }))
    if (errorSet || !resultSet) {
      console.error("application-list:updateUserInfo:error:", errorSet, user.id, userInfo)
    }
  }

  componentDidMount = () => {
    this.props.updateInterviewStatus()
    this.checkApplicationsForProcess()
    this.setState({ queryInit: true, queryLoading: true })
  }

  componentDidUpdate = () => {
    this.checkApplicationsForProcess()
  }

  refetch = () => {
    this.props.applicationsQuery.refetch()
  }

  checkApplicationsForProcess = async () => {
    const { applications } = this.state
    if (this.checkedProcess || !applications || !applications.length) return
    this.checkedProcess = true
    const { recruitment } = this.props
    await Promise.all(applications.filter(a => !a.process || !a.process.length).map(a => this.props.repairApplication({ variables: { applicationId: a.id, recruitmentId: recruitment.id } })))
  }

  processTitle = step => {
    if (this.props.user.isViewer || this.props.user.groupViewer) return
    if (step.state === Process.INVALID) return null
    const name = step.text || ProcessTitlesList[step.order]
    if (!step.start && !step.stop) return name
    const start = step.start ? format(parseISO(step.start), "yy-MM-dd") : "…"
    const stop = step.stop ? format(parseISO(step.stop), "yy-MM-dd") : "…"
    const subType =
      step.state === Process.ACTIVE && (step.type === ProcessType.INTERVIEW1 || step.type === ProcessType.INTERVIEW2) ? (step.subType === ProcessSubType.PHONE ? " (Telefon)" : " (Personlig)") : ""
    return `${name} ${start} - ${stop}${subType}`
  }

  processClick = async (app, step) => {
    if (this.props.user.isViewer || this.props.user.groupViewer) return
    const recruitmentStep = find(rstep => rstep.type === step.type)(this.state.recruitmentProcessSteps)
    if (recruitmentStep.state === Process.INVALID) {
      return
    }
    const processSavingStart = {
      ...this.state.processSaving,
      [app.id]: true,
    }
    this.setState({ processSaving: processSavingStart })
    const newState = step.state === Process.ACTIVE ? Process.INACTIVE : Process.ACTIVE
    let parentState = Process.ACTIVE
    if (newState === Process.INACTIVE) {
      const steps = map(a => find(s => s.type === step.type)(a.process))(this.state.applications)
      const active = filter(s => s.state === Process.ACTIVE)(steps)
      if (active.length <= 1) parentState = Process.INACTIVE
    }
    const mutation = gql`mutation UpdateProcessStep {
      ${step.id}: updateProcessStep(where: { id: "${step.id}" }, data: { state: { set: ${newState} } }) {
        id
        state
      }
      ${recruitmentStep.id}: updateProcessStep(where: { id: "${recruitmentStep.id}" }, data: { state: { set: ${parentState} } }) {
        id
      }
    }`
    const [error, result] = await to(this.props.client.mutate({ mutation }))
    if (error || !result) {
      console.error("application-list:processClick:error", error)
    } else {
      const processSavingEnd = {
        ...this.state.processSaving,
      }
      delete processSavingEnd[app.id]
      const applications = this.state.applications.map(a => ({...a}))
      // const applications = [...this.state.applications]
      applications.forEach(a => {
        if (a.id === app.id) {
          a.process = map(s => {
            return s.id === step.id ? { ...s, state: newState } : s
          })(a.process)
        }
      })
      const filtered = this.state.filtered.length ? filter(a => a && !!find(f => f.id === a.id)(this.state.filtered))(applications) : applications
      const selected = this.state.selected.length ? filter(a => a && !!find(s => s.id === a.id)(this.state.selected))(applications) : []
      this.setState({ processSaving: processSavingEnd, applications, filtered, selected })
      this.updateSelectionStatus()
    }
  }

  process = app =>
    map(recruitmentStep => {
      const steps = app.process
      const applicationStep = find(step => step.type === recruitmentStep.type)(steps)
      if (!applicationStep) {
        console.error("Missing applicationStep for: ", recruitmentStep)
      }
      const firstClass = step => (step.order === 1 ? "start" : step.order === 6 ? "end" : "both")
      const secondClass = step => {
        if (recruitmentStep.state === "INVALID") {
          return " na"
        }
        if (step.state === "ACTIVE") {
          return " started"
        }
        return ""
      }
      const viewerClass = this.props.user.isViewer || this.props.user.groupViewer ? " viewer" : ""
      const processClass = step => firstClass(step) + secondClass(step) + viewerClass
      return !!applicationStep ? (
        <div
          key={applicationStep.id}
          className={`c-processcell ${processClass(applicationStep)}`}
          data-tooltip-content={this.processTitle(applicationStep)}
          onClick={this.processClick.bind(this, app, applicationStep)}
          data-tooltip-id="root-tooltip"
        />
      ) : (
        <div key="missing" className="c-processcell" />
      )
    })(this.state.recruitmentProcessSteps)

  onStatusSubtype = async (type, subType) => {
    if (this.props.user.isViewer || this.props.user.groupViewer) return
    this.setState({ loading: true })
    const steps = map(step => {
      return {
        id: step.id,
        subType: subType,
      }
    })(map(a => find(p => p.type === type)(a.process))(this.state.selected))
    const mutation = gql`
      mutation UpdateProcessStepBatch($steps: String!) {
        updateProcessStepBatch(steps: $steps)
      }
    `
    const variables = {
      steps: JSON.stringify(steps),
    }
    const [error, result] = await to(this.props.client.mutate({ mutation, variables }))
    if (error || !result) {
      console.error("application-list:onStatusSubtype:error", error)
      return
    }
    const applications = cloneDeep(this.state.applications)
    this.state.selected.forEach(s => {
      const application = find(a => a.id === s.id)(applications)
      if (application) {
        application.process = map(step => {
          if (find(p => p.id === step.id)(steps)) {
            step.subType = subType
          }
          return step
        })(application.process)
      }
    })
    const filtered = this.state.filtered.length ? filter(a => a && !!find(f => f.id === a.id)(this.state.filtered))(applications) : applications
    const selected = this.state.selected.length ? filter(a => a && !!find(s => s.id === a.id)(this.state.selected))(applications) : []
    this.setState({ loading: false, applications, filtered, selected })
  }

  processSubType = step => {
    if (step.type === ProcessType.INTERVIEW1 || step.type === ProcessType.INTERVIEW2) {
      return <SubtypeToggle step={step} data-tooltip-content="Klicka för att ändra intervjutyp" data-tooltip-id="root-tooltip" onToggle={this.onStatusSubtype} />
    }
    return null
  }

  date = date => {
    if (!date || typeof date !== "string" || date.length < 10) {
      return " "
    }
    return format(parseISO(date), "yy-MM-dd")
  }

  status = steps => {
    const step = steps.findLast(s => s.state === Process.ACTIVE)
    return step ? this.processNames[step.order - 1] : "-"
  }

  selectAll = e => {
    let selected = []
    if (e.target.checked) {
      selected = [...this.state.filtered]
    }
    this.setState({ allSelected: e.target.checked, selected })
  }

  selectApplication = e => {
    let selected = [...this.state.selected]
    const id = e.target.value
    if (e.target.checked) {
      if (!find(f => f.id === id)(selected)) {
        const application = find(f => f.id === id)(this.state.filtered)
        selected.push(application)
      }
    } else {
      selected = remove(application => application.id === id)(selected)
    }
    this.setState({ allSelected: false, selected })
  }

  onSymbolStart = () => {
    if (this.props.user.isViewer || this.props.user.groupViewer) return
    this.setState({ loading: true })
  }

  onSymbolEnd = (symbolIds, action, symbols) => {
    const applications = cloneDeep(this.state.applications)
    if (action === "addToApplicationSymbols") {
      symbolIds.forEach(target => {
        const match = find(a => a.id === target.applicationsApplicationId)(applications)
        if (match) {
          const symbol = find(s => s.id === target.symbolsSymbolId)(symbols)
          if (!find(s => s.id === symbol.id)(match.symbols)) {
            match.symbols = [...match.symbols, {...symbol, symbolTexts: symbol.symbolTexts || []}]
          }
        }
      })
    } else {
      symbolIds.forEach(target => {
        const match = find(a => a.id === target.applicationsApplicationId)(applications)
        if (match) {
          match.symbols = remove(s => s.id === target.symbolsSymbolId)(match.symbols)
        }
      })
    }
    const filtered = this.state.filtered.length ? filter(a => a && !!find(f => f.id === a.id)(this.state.filtered))(applications) : applications
    const selected = this.state.selected.length ? filter(a => a && !!find(s => s.id === a.id)(this.state.selected))(applications) : []
    this.setState({ loading: false, applications, filtered, selected })
  }

  onGrade = async grade => {
    if (this.props.user.isViewer || this.props.user.groupViewer) return
    if (!this.state.selected?.length) return
    this.setState({ loading: true })
    const grades = map(a => {
      return {
        id: a.id,
        grade: grade,
      }
    })(this.state.selected)
    const mutation = gql`
      mutation UpdateApplicationGradeBatch($recruitmentId: String!, $grades: String!) {
        updateApplicationGradeBatch(recruitmentId: $recruitmentId, grades: $grades)
      }
    `
    const variables = {
      recruitmentId: this.props.recruitment && this.props.recruitment.id,
      grades: JSON.stringify(grades),
    }
    const [error, result] = await to(this.props.client.mutate({ mutation, variables }))
    if (error || !result) {
      console.error("application-list:onGrade:error", error)
    }
    const applications = this.state.applications.map(a => {
      const match = find(g => g.id === a.id)(grades)
      return match ? {...a, grade: match.grade} : a
    })
    const filtered = this.state.filtered.length ? filter(a => a && !!find(f => f.id === a.id)(this.state.filtered))(applications) : applications
    const selected = this.state.selected.length ? filter(a => a && !!find(s => s.id === a.id)(this.state.selected))(applications) : []
    this.setState({ gradeSelect: false, applications, filtered, selected })
    this.updateSelectionStatus()
    this.setState({ loading: false })
  }

  onSingleGrade = async (id, grade, callback) => {
    const mutation = gql`mutation UpdateApplication {
      updateApplication(where: { id: "${id}" }, data: { grade: { set: ${grade} } }) {
        id
      }
    }`
    const [error, result] = await to(this.props.client.mutate({ mutation }))
    callback(!!result)
    if (error || !result) {
      console.error("application-list:onSingleGrade:error", error)
    } else {
      this.updateSelectionStatus()
      const applications = cloneDeep(this.state.applications)
      let application = find(a => a.id === id)(applications)
      application.grade = grade
      const filtered = this.state.filtered.length ? filter(a => a && !!find(f => f.id === a.id)(this.state.filtered))(applications) : applications
      const selected = this.state.selected.length ? filter(a => a && !!find(s => s.id === a.id)(this.state.selected))(applications) : []
      this.setState({ applications, filtered, selected })
    }
  }

  updateSelectionStatus = async () => {
    const selectionId = this.props.recruitment.process[0].id
    const mutation = gql`mutation UpdateProcessStep {
      updateProcessStep(where: { id: "${selectionId}" }, data: { state: { set: ACTIVE } }) {
        id
      }
    }`
    const [error, result] = await to(this.props.client.mutate({ mutation }))
    if (error || !result) {
      console.error("application-list:updateSelectionStatus:error", error)
    } else {
      this.updateOverviewCache()
    }
  }

  onSingleNotes = async (id, notes) => {
    if (notes === this.singleNotes) return
    // const notesSavingStart = {
    //   ...this.state.notesSaving,
    //   [id]: true,
    // }
    // this.setState({ notesSaving: notesSavingStart })
    const variables = { appId: id, notes: notes || "" }
    const mutation = gql`
      mutation UpdateApplication($appId: String!, $notes: String) {
        updateApplication(where: { id: $appId }, data: { notes: { set: $notes } }) {
          id
        }
      }
    `
    const [error, result] = await to(this.props.client.mutate({ mutation, variables }))
    if (error || !result) {
      console.error("application-list:onSingleNotes:error", error)
    } else {
      // const notesSavingEnd = {
      //   ...this.state.notesSaving,
      // }
      // delete notesSavingEnd[id]
      const applications = cloneDeep(this.state.applications)
      const application = find(a => a.id === id)(applications)
      application.notes = notes
      const filtered = this.state.filtered.length ? filter(a => a && !!find(f => f.id === a.id)(this.state.filtered))(applications) : applications
      const selected = this.state.selected.length ? filter(a => a && !!find(s => s.id === a.id)(this.state.selected))(applications) : []
      this.setState({ applications, filtered, selected })
      // this.setState({ notesSaving: notesSavingEnd, applications, filtered, selected })
    }
  }

  getCommonStatus = () => {
    const active = map(app => filter(s => s.state === Process.ACTIVE)(app.process))(this.state.selected)
    const first = active.shift()
    return filter(s => {
      let found = true
      active.forEach(c => (found = found && !!find(status => status.type === s.type)(c)))
      return found
    })(first)
  }

  onToggleStatus = () => {
    const commonStatus = !this.state.statusSelect ? this.getCommonStatus() : []
    this.setState({ statusSelect: !this.state.statusSelect, commonStatus })
  }

  onStatus = async (e, step) => {
    this.setState({ loading: true })
    const checked = e.target.checked
    const state = checked ? Process.ACTIVE : Process.INACTIVE
    const steps = map(a => {
      const processStep = find(p => p.type === step.type)(a.process)
      return {
        id: processStep.id,
        state: state,
      }
    })(this.state.selected)
    const mutation = gql`
      mutation UpdateProcessStepBatch($steps: String!) {
        updateProcessStepBatch(steps: $steps)
      }
    `
    const variables = {
      steps: JSON.stringify(steps),
    }
    const [error, result] = await to(this.props.client.mutate({ mutation, variables }))
    if (error || !result) {
      console.error("application-list:onStatus:error", error)
    }
    const applications = cloneDeep(this.state.applications)
    this.state.selected.forEach(s => {
      const application = find(a => a.id === s.id)(applications)
      if (application) {
        application.process = map(step => {
          if (find(p => p.id === step.id)(steps)) {
            step.state = state
          }
          return step
        })(application.process)
      }
    })
    const filtered = this.state.filtered.length ? filter(a => a && !!find(f => f.id === a.id)(this.state.filtered))(applications) : applications
    const selected = this.state.selected.length ? filter(a => a && !!find(s => s.id === a.id)(this.state.selected))(applications) : []
    let commonStatus = [...this.state.commonStatus]
    if (checked) commonStatus.push(step)
    else commonStatus = remove(c => c.type === step.type)(commonStatus)
    this.setState({ commonStatus, applications, filtered, selected, loading: false })
  }

  checkAnswers = (application, question, answers) => {
    if (!application.answers) return false
    const appAnswer = application.answers[question.id]
    if (!appAnswer) return false
    if (!answers || !answers.length) return true
    if (question.type === AnswerType.TEXT) {
      return answers[0] && appAnswer.length
    } else if (question.type === AnswerType.BOOL) {
      return answers.indexOf(appAnswer) > -1
    } else if (question.type === AnswerType.MULTIPLE) {
      const appAnswers = appAnswer ? appAnswer.split("|#") : []
      for (let i = 0; i < answers.length; i++) {
        if (appAnswers.indexOf(answers[i]) < 0) return false
      }
      return true
    } else {
      // question.type === AnswerType.SINGLE
      return answers.indexOf(appAnswer) > -1
    }
  }

  onFilter = (question, filterAnswers) => {
    if (!!filterAnswers && filterAnswers.length) {
      this.selectionFilter[question.id] = { question, filterAnswers }
    } else if (this.selectionFilter[question.id]) {
      delete this.selectionFilter[question.id]
    }
    this.applyFilter()
  }

  clearFilter = () => {
    this.selectionFilter = {}
    this.applyFilter()
  }

  getFiltered = applications => {
    let filtered = [...applications]
    Object.keys(this.selectionFilter).forEach(id => {
      const selection = this.selectionFilter[id]
      filtered = filter(application => application && this.checkAnswers(application, selection.question, selection.filterAnswers))(filtered)
    })
    return filtered
  }

  applyFilter = () => {
    const filtered = this.getFiltered(this.state.applications)
    this.setState({ filtered })
  }

  openApplication = (e, id) => {
    e.preventDefault()
    const app = window.open(`/${this.props.recruitment.company.urlName}${REK_APPLICATION}/${id}?q=1`, id, "width=1200,height=800,location=no")
    this.apps.push(app)
    return false
  }

  slug = input => {
    return (
      input &&
      input
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .replace(/[^a-zA-Z0-9\s]/g, "")
        .replace(/\s+/g, "-")
        .toLowerCase()
    )
  }

  openJobAd = e => {
    e.preventDefault()
    const { jobAd, title } = this.props.recruitment
    const { company } = this.state
    if (!jobAd || !title || !company) return ""
    const titleSlug = this.slug(title)
    const companySlug = this.slug(company.name)
    const url = isLive() ? `https://www.jobbet.se${JOBB}/${companySlug}/${titleSlug}/${jobAd.id}` : `http://jobbet.devserver.phosdev.se${JOBB}/${companySlug}/${titleSlug}/${jobAd.id}`
    window.open(url, jobAd.id, "width=1200,height=800,location=0")
    return false
  }

  setSortOrder = (prop, inOrder) => {
    const order = sortOrder[prop] === "" ? inOrder || "desc" : sortOrder[prop] !== "asc" ? "asc" : "desc"
    sortProp = prop
    sortOrder = { name: "", grade: "", createdAt: "", symbols: "", process: "" }
    sortOrder[prop] = order
  }

  sortName = e => {
    if (e && e.target.tagName.toLowerCase() !== "div") return
    this.setSorted("name", "asc", "namn")
  }

  sortDate = () => {
    this.setSorted("createdAt", "desc", "datum")
  }

  sortGrade = () => {
    this.setSorted("grade", "desc", "gradering")
  }

  sortSymbols = () => {
    if (this.state.symbolSortingId) {
      this.setSorted("symbols", "asc", "symboler")
    } else {
      this.setSorted("createdAt", "desc", "symboler")
    }
  }

  sortProcess = () => {
    this.setSorted("process", "desc", "status")
  }

  sortNotes = () => {
    this.setSorted("notes", "desc", "anteckningar")
  }

  sortToggle = () => {
    switch (this.state.currentSort) {
      case "namn":
        this.sortDate()
        break
      case "datum":
        this.sortGrade()
        break
      case "gradering":
        this.sortProcess()
        break
      default:
        this.sortName()
    }
  }

  setSorted = (prop, inOrder, name) => {
    this.setSortOrder(prop, inOrder)
    const sorted = ApplicationListClass.getSorted(this.state.applications, this.state.symbolSortingId)
    const symbolSortingId = prop === "symbols" ? this.state.symbolSortingId : ""
    this.setState({ applications: sorted, filtered: this.getFiltered(sorted), symbolSortingId, currentSort: name })
  }

  static getSorted = (applications, symbolSortingId) => {
    if (!applications || !applications.length) return []
    switch (sortProp) {
      case "grade":
        return orderBy(["grade"])([sortOrder.grade])(applications)
      case "createdAt":
        return orderBy(["createdAt"])([sortOrder.createdAt])(applications)
      case "symbols":
        applications.sort((a, b) => {
          return !!a.symbols.find(s => s.id === symbolSortingId) ? -1 : !!b.symbols.find(s => s.id === symbolSortingId) ? 1 : 0
        })
        return applications
      case "process":
        return orderBy([a => filter(p => p.state === Process.ACTIVE)(a.process).length])([sortOrder.process])(applications)
      case "name":
        return orderBy(a => `${a.candidate.user.firstName.toLowerCase()} ${a.candidate.user.lastName.toLowerCase()}`)([sortOrder.name])(applications)
      case "notes":
        return orderBy([a => !!a.notes])([sortOrder.notes])(applications)
      default:
        return applications
    }
  }

  sendEmail = e => {
    this.openModal("email")
  }

  sendInvite = e => {
    this.openModal("booking")
  }

  shareApplications = e => {
    this.openModal("share")
  }

  openModal = dialog => {
    let newState = {
      emailModalOpen: false,
      bookingModalOpen: false,
      shareModalOpen: false,
      messageLogModalOpen: false,
    }
    switch (dialog) {
      case "email":
        newState.emailModalOpen = true
        break
      case "booking":
        newState.bookingModalOpen = true
        break
      case "share":
        newState.shareModalOpen = true
        break
      case "messageLog":
        newState.messageLogModalOpen = true
        break
      default:
        break
    }
    this.setState(newState)
  }

  closeModal = () => {
    this.openModal(null)
  }

  deleteApplications = async e => {
    const selected = this.state.selected.filter(s => this.state.filtered.find(f => f.id === s.id))
    const text = selected.length > 1 ? selected.length + " markerade ansökningar" : "markerad ansökan"
    const confirmed = await confirm(`Vill du ta bort ${text}?`)
    if (!confirmed) return
    this.setState({ loading: true })
    const deleteApplications = selected
      .map(a => {
        return `${a.id}: deleteApplication(where: { id: "${a.id}" }) { id }`
      })
      .join("\n")
    const mutation = gql`mutation DeleteApplications {
      ${deleteApplications}
    }`
    const [error, result] = await to(this.props.client.mutate({ mutation }))
    if (error || !result) {
      console.error("application-list:deleteApplications:error", error)
    }
    this.setState({ loading: false })
    this.refetch()
  }

  onModalClose = e => {
    this.closeModal()
  }

  getSymbolText = symbol => {
    return symbol.customText || (symbol.symbolTexts?.length ? symbol.symbolTexts[0].text : symbol.text)
  }

  handleSymbolTextChange = async (symbol, newText, callback) => {
    const [err, res] = await to(
      this.props.setSymbolText({
        symbolTextId: symbol.customTextId || cuid(),
        text: newText,
        recruitmentId: this.props.recruitment.id,
        symboldId: symbol.id,
      })
    )
    callback && callback(!!res)
    if (err || !res) {
      console.error("application-list:handleSymbolTextChange:error:", err)
    } else {
      this.apps.forEach(app => app && app.postMessage({ operation: { operationName: "SymbolTextMutation" } }))
      const applications = cloneDeep(this.state.applications)
      applications.forEach(a => {
        const match = find(s => s.id === symbol.id)(a.symbols)
        if (match) {
          match.customText = match.text = newText
        }
      })
      const filtered = this.state.filtered.length ? filter(a => a && !!find(f => f.id === a.id)(this.state.filtered))(applications) : applications
      const selected = this.state.selected.length ? filter(a => a && !!find(s => s.id === a.id)(this.state.selected))(applications) : []
      this.setState({ applications, filtered, selected, symbolsKey: Math.ceil(Math.random() * 10) })
    }
  }

  onSymbolSorting = symbolId => {
    this.setState({ symbolSortingId: symbolId, symbolSorting: false }, this.sortSymbols)
  }

  hasWriteAccess = () => {
    return this.props.user && [UserRoles.GROUPVIEWER, UserRoles.VIEWER].includes(this.props.user.role) === false
  }

  updateOverviewCache() {
    const { state } = this.props.recruitment
    const recruitmentStats = {
      __typename: "RecruitmentStats",
      id: REC_STATS_ID,
      countRecruitmentsIsStale: true,
      activeRecruitmentsIsStale: state === RecruitmentState.ACTIVE,
      draftRecruitmentsIsStale: state === RecruitmentState.DRAFT,
      closedRecruitmentsIsStale: state === RecruitmentState.CLOSED,
    }
    this.props.client.writeQuery({
      query: gql`
        query RecruitmentStats {
          recruitmentStats {
            countRecruitmentsIsStale
            activeRecruitmentsIsStale
            draftRecruitmentsIsStale
            closedRecruitmentsIsStale
          }
        }
      `,
      data: {
        recruitmentStats,
      },
    })
  }

  onApplicationAction(application, action) {
    switch (action) {
      case "message":
        this.setState({ applicationId: application.id })
        this.openModal("messageLog")
        return
      default:
        return
    }
  }

  applicationMailingOnUpdate = e => {
    this.refetch()
  }

  applicationMailingOnError = error => {
    message("Ett fel inträffade när e-post skulle skickas.")
  }

  applicationInterviewsOnUpdate = e => {
    this.refetch()
  }

  applicationInterviewsOnError = error => {
    message("Ett fel inträffade när inbjudan skulle skickas.")
  }

  getTooltipSymbolPickerForApplication = props => {
    if (!props?.content) return null
    const { filtered: filteredApplications } = this.state
    const { recruitmentSymbols: symbols = [] } = this.props.recruitmentSymbolsQuery
    const applications = filteredApplications.filter(app => app.id === props.content)
    return (
      <SymbolPicker
        recruitmentId={this.props.recruitment && this.props.recruitment.id}
        applications={applications}
        symbols={symbols}
        onSymbolTextChange={this.handleSymbolTextChange}
        onSelectedChange={this.onSymbolEnd}
        maxHeight
      />
    )
  }

  getNotesTooltipForApplication = props => {
    if (!props?.content) return null
    const { filtered: filteredApplications, notesKey } = this.state
    const application = find(app => app.id === props.content)(filteredApplications)
    if (!application) return null
    return (
      <NotesInput
        key={notesKey}
        readOnly={this.props.user.isViewer || this.props.user.groupViewer}
        id={application.id}
        notes={application.notes ? application.notes.replaceAll("</br>", "\n") : ""}
        onChange={this.onSingleNotes}
      />
    )
  }

  getGradeTooltipForApplication = props => {
    if (!props?.content) return null
    const { filtered: filteredApplications } = this.state
    const application = find(app => app.id === props.content)(filteredApplications)
    if (!application) return null
    return <GradeList $padding="5px" application={application} onGrade={(grade, callback) => this.onSingleGrade(props.content, grade, callback)} />
  }

  setNotesKey = () => {
    let key = Math.ceil(Math.random() * 10)
    if (key === this.state.notesKey) key++
    this.setState({ notesKey: key })
  }

  render() {
    const { user, match, menu, recruitment, recruitmentSymbolsQuery } = this.props
    if (!recruitment) return null
    const {
      id: recruitmentId,
      title,
      recruiter,
      recruiterExternals,
      selection,
      recruitmentSelectedPublishChannels,
      publishChannelsOther,
      applyStop,
      jobAd,
      addons,
      company,
      settings,
    } = recruitment
    const applyStopDate = applyStop && parseISO(applyStop)
    const activeChannels = recruitmentSelectedPublishChannels && recruitmentSelectedPublishChannels.filter(c => c.activated)
    const { loading: symbolsLoading, recruitmentSymbols: symbols = [] } = recruitmentSymbolsQuery

    const {
      showConfirmOrderModal,
      mobile,
      loading,
      applications,
      filtered,
      commonStatus,
      recruitmentProcessSteps,
      allSelected,
      gradeSelect,
      symbolSelect,
      statusSelect,
      filterSelect,
      symbolSorting,
      symbolSortingId,
      emailTemplates,
      emailModalOpen,
      bookingModalOpen,
      shareModalOpen,
      messageLogModalOpen,
      applicationId,
      queryInit,
      queryLoading,
      processSaving,
      // notesSaving,
      orderMessage,
      company: { jobbetContacts: contacts },
      currentSort,
      symbolsKey,
    } = this.state

    const bookable = [...addons]
    if (activeChannels.length > 1 || (activeChannels.length === 1 && activeChannels[0].publishChannel.title !== "Karriärsida")) {
      bookable.push({ id: "Annonsering", name: "Marknadsföring/annonsering" })
    }

    const { isViewer, groupViewer, companyView, superAdmin } = user
    const viewer = isViewer || groupViewer
    const selected = this.state.selected.filter(s => filtered.find(f => f.id === s.id))
    const noActions = selected.length < 1
    const noBooking = !!selected.find(s => !!s.interviews.find(i => i.status === "BOOKED"))
    const COMPANY = "/" + (match.params.company || companyView.urlName)
    const realUrl = `${COMPANY}` + url.REKRYTERING + (match.params.state ? `/${match.params.state}` : url.REK_NY) + `/${match.params.recruitment}`
    const openMenuClass = menu.mainMenuOpen ? " side-menu-open" : ""
    const daysLeft = recruitment.applyStart && 90 - differenceInDays(new Date(), parseISO(recruitment.applyStart))

    return (
      <Content className={loading && "block-user-input"}>
        <Intro className={openMenuClass}>
          <IntroBox className={"position-container" + openMenuClass} $flex={1}>
            <div className="subpart subpart-top">
              <Title>
                <span className="title">Platsannons</span>
                <div className="right">
                  <PrimaryLink className="cta-alone" onClick={this.openJobAd}>
                    Öppna
                  </PrimaryLink>
                </div>
              </Title>

              <div className="counts-container">
                <DateAndInfo>
                  <div className="info">
                    <span className="count">{jobAd && jobAd.views ? jobAd.views : 0}</span>
                    <span>Visningar</span>
                  </div>
                  <div className="v-divider"></div>
                  <div className="info">
                    <span className="count">{applications.length}</span>
                    <span>Ansökningar</span>
                  </div>
                </DateAndInfo>
              </div>
              <div className="divider no-border">
                {applyStopDate && isValid(applyStopDate) ? (
                  <>
                    <span className="small">Sista ansökningsdag</span>
                    <span className="fill"> {format(applyStopDate, "yy-MM-dd")}</span>
                  </>
                ) : (
                  <span className="error fill">Datum saknas</span>
                )}
              </div>

              <div className="divider">
                <span className="small">Rekryterande chef</span>
                <span className="fill">{recruiter ? `${recruiter.firstName} ${recruiter.lastName}` : "-"}</span>
              </div>
              {recruitment.company.subscription != HAS_SUBSCRIPTION && daysLeft > 0 && (
                <div className="divider">
                  <span className="fill highvis">Avtal saknas ({daysLeft} dagar kvar att se rekryteringen)</span>
                </div>
              )}
            </div>
            <div className="subpart">
              <div className="contacts-container">
                <div className="contacts">
                  {!!recruiterExternals.length && (
                    <>
                      <span className="small">Rekryterare Jobbet.se</span>
                      <Contacts>
                        {map(contact => (
                          <Contact key={contact.id}>
                            <ContactImage data-tooltip-content={contact.firstName + " " + contact.lastName} data-tooltip-id="root-tooltip">
                              {contact && contact.picture && contact.picture.url && <img src={contact.picture.url} alt={contact.firstName + " " + contact.lastName} />}
                            </ContactImage>
                            <span>
                              {contact && contact.email && (
                                <ContactLink href={"mailto:" + contact.email} data-tooltip-content={contact.email} data-tooltip-id="root-tooltip">
                                  <MailIcon />
                                  {contact.firstName + " " + contact.lastName}
                                </ContactLink>
                              )}
                              <br />
                              {contact && (contact.phone || contact.mobile) && <ContactLink href={"tel:" + contact.mobile}>{contact.mobile}</ContactLink>}
                            </span>
                          </Contact>
                        ))(recruiterExternals.slice(0, 2))}
                      </Contacts>
                    </>
                  )}
                  {contacts && contacts.length > 0 && !recruiterExternals.length && (
                    <>
                      <span className="fill">Vill du ha hjälp med din rekrytering? Kontakta oss</span>
                      <Contacts>
                        {contacts.map(contact => (
                          <Contact key={contact.id}>
                            <ContactImage data-tooltip-content={contact.firstName + " " + contact.lastName} data-tooltip-id="root-tooltip">
                              {contact && contact.picture && contact.picture.url && <img src={contact.picture.url} alt={contact.firstName + " " + contact.lastName} />}
                            </ContactImage>
                            <span>
                              {contact && contact.email && (
                                <ContactLink href={"mailto:" + contact.email} data-tooltip-content={contact.email} data-tooltip-id="root-tooltip">
                                  <MailIcon />
                                  {contact.firstName + " " + contact.lastName}
                                </ContactLink>
                              )}
                              <br />
                              {contact && (contact.phone || contact.mobile) && <ContactLink href={"tel:" + contact.mobile}>{contact.mobile}</ContactLink>}
                            </span>
                          </Contact>
                        ))}
                      </Contacts>
                    </>
                  )}
                </div>
              </div>
            </div>
          </IntroBox>
          <div className="break-flex"></div>
          <IntroBox className={"channel-container" + openMenuClass} $minWidth="320px" $flex={1}>
            <CandidateAcquisitionSummary
              disableCareer={!this.state.company.careerPage}
              openOrder={this.openOrderModular}
              addons={[...addons]}
              adMeterScore={settings?.adMeterScore}
              adMeterBookedScore={settings?.adMeterBookedScore}></CandidateAcquisitionSummary>
          </IntroBox>
          <IntroBox className={"service-container" + openMenuClass}>
            <ServicesSummary openOrder={this.openOrderModular} addons={[...addons]} user={user} realUrl={realUrl}></ServicesSummary>
          </IntroBox>
        </Intro>
        <Wrapper className="application-list">
          <div>
            <span className="heading">Ansökningar</span>
            {!mobile && (
              <NavLink className="fr" to={COMPANY + INTERVIEW}>
                Till tidsbokningen
              </NavLink>
            )}
          </div>
          <Actions>
            {this.hasWriteAccess() && (
              <MultipleBoxWrapper className={mobile && noActions ? "hidden" : ""}>
                <MultipleBox data-tooltip-content={noActions ? "Markera minst en ansökan nedan." : null} data-tooltip-id="root-tooltip">
                  <BoxTitle>Flervalsalternativ</BoxTitle>
                  <DropDown
                    label="Gradering"
                    disabled={noActions}
                    open={gradeSelect}
                    toggle={() => {
                      this.setState({ gradeSelect: !gradeSelect })
                    }}>
                    <MultipleGradeList>
                      <li>
                        <Grade level="1" name="grade" onChange={e => this.onGrade(1)} />
                        <span>Ofullständig ansökan</span>
                      </li>
                      <li>
                        <Grade level="2" name="grade" onChange={e => this.onGrade(2)} />
                        <span>Motsvarar ej kravprofil</span>
                      </li>
                      <li>
                        <Grade level="3" name="grade" onChange={e => this.onGrade(3)} />
                        <span>Motsvarar delvis kravprofil</span>
                      </li>
                      <li>
                        <Grade level="4" name="grade" onChange={e => this.onGrade(4)} />
                        <span>Motsvarar i stort sett kravprofil</span>
                      </li>
                      <li>
                        <Grade level="5" name="grade" onChange={e => this.onGrade(5)} />
                        <span>Motsvarar kravprofil</span>
                      </li>
                    </MultipleGradeList>
                  </DropDown>
                  {!symbolsLoading && (
                    <DropDown label="Symboler" disabled={noActions} open={symbolSelect} toggle={() => this.setState({ symbolSelect: !symbolSelect })}>
                      <SymbolPicker
                        recruitmentId={recruitmentId}
                        applications={this.state.selected}
                        symbols={symbols}
                        onSymbolTextChange={this.handleSymbolTextChange}
                        onSelectedChangeStart={this.onSymbolStart}
                        onSelectedChange={this.onSymbolEnd}
                      />
                    </DropDown>
                  )}
                  <DropDown label="Rekryteringsstatus" disabled={noActions} open={statusSelect} toggle={this.onToggleStatus}>
                    <StatusList>
                      {map(step => (
                        <li key={step.id}>
                          <Checkbox value={step.id} onChange={e => this.onStatus(e, step)} checked={!!find(s => s.type === step.type)(commonStatus)} />
                          <span>{ProcessTitlesList[step.order]}</span>
                          {this.processSubType(step)}
                        </li>
                      ))(recruitmentProcessSteps?.filter(s => s.state !== Process.INVALID) || [])}
                    </StatusList>
                  </DropDown>
                </MultipleBox>
                <ButtonBox $minWidth="200px" data-tooltip-content={noActions ? "Markera minst en ansökan nedan." : null} data-tooltip-id="root-tooltip">
                  <SecondaryButton disabled={noActions} onClick={this.sendEmail}>
                    E-post
                  </SecondaryButton>
                  <SecondaryButton disabled={noActions || noBooking} onClick={this.sendInvite}>
                    Boka tid
                  </SecondaryButton>
                  <SecondaryButton disabled={noActions} onClick={this.shareApplications}>
                    Dela
                  </SecondaryButton>
                  {superAdmin && (
                    <SecondaryButton disabled={noActions} onClick={this.deleteApplications}>
                      Ta bort
                    </SecondaryButton>
                  )}
                </ButtonBox>
              </MultipleBoxWrapper>
            )}
            <SelectionBox>
              <BoxTitle>
                Filtrera på urvalsfrågor (visar {filtered.length} av {applications.length})
              </BoxTitle>
              <DropDown
                label="Urvalsfrågor"
                open={filterSelect}
                toggle={() => {
                  this.setState({ filterSelect: !filterSelect })
                }}
                right
                noMargin>
                <Selection>
                  {selection.map(question => (
                    <FilterQuestion
                      key={question.id}
                      question={question}
                      selected={this.selectionFilter[question.id] && this.selectionFilter[question.id].filterAnswers}
                      onChange={answers => this.onFilter(question, answers)}
                    />
                  ))}
                </Selection>
              </DropDown>
              {!isEmpty(this.selectionFilter) && (
                <a className="reset-link" onClick={this.clearFilter}>
                  Återställ
                </a>
              )}
            </SelectionBox>
          </Actions>
          {!mobile && (
            <Row className="header">
              <TitleCell onClick={this.sortName}>
                <Checkbox checked={allSelected} onChange={this.selectAll} />
                Sökande
                <Arrow className={sortOrder.name} />
              </TitleCell>
              <InfoArea>
                <InfoCell onClick={this.sortDate}>
                  Ansökningsdatum
                  <Arrow className={sortOrder.createdAt} />
                </InfoCell>
                <InfoCell onClick={this.sortGrade}>
                  Gradering
                  <Arrow className={sortOrder.grade} />
                </InfoCell>
                <DropCell $flex="2">
                  <DropDown
                    label="Symboler"
                    open={symbolSorting}
                    toggle={() => {
                      this.setState({ symbolSorting: !symbolSorting })
                    }}
                    right
                    noMargin>
                    <SymbolSorter symbols={symbols} symbolId={symbolSortingId} onChange={this.onSymbolSorting} maxHeight />
                  </DropDown>
                </DropCell>
              </InfoArea>
              <ProcessArea onClick={this.sortProcess}>{map(name => <ProcessCell key={name}>{name}</ProcessCell>)(this.processNames)}</ProcessArea>
              <Notes onClick={this.sortNotes}>Not</Notes>
            </Row>
          )}
          {mobile && (
            <Row className="header">
              <TitleCell>
                <Checkbox checked={allSelected} onChange={this.selectAll} label="Välj alla" />
                <SortMobile onClick={this.sortToggle}>
                  Sortering: <span>{currentSort}</span>
                </SortMobile>
              </TitleCell>
            </Row>
          )}
          <Rows className={queryLoading && "loading"}>
            {queryInit && <Spinner />}
            {map(app => (
              <Row className="content" key={app.id}>
                <TitleCell>
                  <Checkbox disabled={app.candidate.expired} value={app.id} checked={allSelected || !!find(s => s.id === app.id)(selected)} onChange={this.selectApplication} />
                  {app.candidate.expired && <TitleSpan>{`${app.candidate.user.firstName} ${app.candidate.user.lastName}`}</TitleSpan>}
                  {!app.candidate.expired && (
                    <TitleLink
                      data-tooltip-content={app.expired ? "Utgången ansökan" : null}
                      data-tooltip-id="root-tooltip"
                      className={app.expired ? "expired" : null}
                      onClick={e => this.openApplication(e, app.id)}>{`${app.candidate.user.firstName} ${app.candidate.user.lastName}`}</TitleLink>
                  )}
                  {!app.expired &&
                    <ApplicationActions>
                      {app.messageLogCount > 0 &&
                      <ActionSymbol key="message" data-tooltip-content={app.messageLogCount + (app.messageLogCount > 1 ? " skickade meddelanden" : " skickat meddelande")} data-tooltip-id="root-tooltip" onClick={e => this.onApplicationAction(app, "message")}>
                        <img src={icons["message"]} className="message" alt="" />
                      </ActionSymbol>
                      }
                      {!!app.interviews.find(i => i.status === "BOOKED") && (
                        <CheckIcon
                          data-tooltip-html={"Accepterat inbjudan<br/>" + format(parseISO(app.interviews.find(i => i.status === "BOOKED").interview.startTime), "yy-MM-dd HH:mm")}
                          data-tooltip-id="root-tooltip"
                        />
                      )}
                      {!!app.interviews.find(i => i.status === "INVITED") && <Hourglass data-tooltip-content="Skickat inbjudan" data-tooltip-id="root-tooltip" />}
                    </ApplicationActions>
                  }
                </TitleCell>
                {!mobile && (
                  <InfoArea>
                    <InfoCell className="date">{this.date(app.createdAt)}</InfoCell>
                    <InfoCell className="grade">
                      <div className="grade-wrapper">{app.grade > 0 ? app.grade : 0}</div>
                      {viewer || <Menu data-tooltip-content={app.id} data-tooltip-id={"grading"} />}
                    </InfoCell>
                    <InfoCell $flex="2" $left key={symbolsKey}>
                      {app.symbols && app.symbols.length > 0 &&
                        map(symbol => (
                          <i className="svg-symbol-icons" key={symbol.id} data-tooltip-content={this.getSymbolText(symbol)} data-tooltip-id="root-tooltip">
                            <img src={icons[symbol.name]} className={symbol.name} alt="Symbol för rekryteringsprocess" />
                          </i>
                        ))(app.symbols)}
                      {!viewer && !symbolsLoading && <Menu data-tooltip-content={app.id} data-tooltip-id={"symbolsPicker"} />}
                    </InfoCell>
                  </InfoArea>
                )}
                {!mobile && <ProcessArea>{processSaving[app.id] || !app.process.length ? <SmallSpinner /> : this.process(app)}</ProcessArea>}
                <Notes $active={!!app.notes}><i data-tooltip-content={app.id} data-tooltip-id={"notesTip"} /></Notes>
                {/* <Notes $active={!!app.notes}>{notesSaving[app.id] ? <SmallSpinner /> : <i data-tooltip-content={app.id} data-tooltip-id={"notesTip"} />}</Notes> */}
                {mobile && (
                  <Narrow onClick={e => this.openApplication(e, app.id)}>
                    <div className="symbols">
                      {app.symbols?.length > 0 &&
                        app.symbols.map(symbol => (
                          <i className="svg-symbol-icons" key={symbol.id} data-tooltip-content={this.getSymbolText(symbol)} data-tooltip-id="root-tooltip">
                            <img src={icons[symbol.name]} className={symbol.name} alt="Symbol för rekryteringsprocess" />
                          </i>
                        ))}
                    </div>
                    <div>
                      <label>Ansökningsdatum:</label>
                      <span>{this.date(app.createdAt)}</span>
                    </div>
                    <div>
                      <label>Gradering:</label>
                      <span>{app.grade > 0 ? app.grade : 0}</span>
                    </div>
                    <div>
                      <label>Status:</label>
                      <span>{this.status(app.process)}</span>
                    </div>
                  </Narrow>
                )}
              </Row>
            ))(filtered)}
            {viewer || (
              <Tooltip
                id={"grading"}
                render={this.getGradeTooltipForApplication}
                variant="light"
                border="1px solid var(--color-line)"
                place="bottom"
                effect="solid"
                delayHide={500}
                className="stay"
                clickable
              />
            )}
            {viewer || (
              <Tooltip
                id={"symbolsPicker"}
                render={this.getTooltipSymbolPickerForApplication}
                variant="light"
                border="1px solid var(--color-line)"
                place="bottom"
                effect="solid"
                delayHide={500}
                className="symbolPicker"
                clickable
              />
            )}
            <Tooltip
              id={"notesTip"}
              render={this.getNotesTooltipForApplication}
              variant="light"
              border="1px solid var(--color-line)"
              place="left"
              effect="solid"
              afterShow={this.setNotesKey}
              delayHide={700}
              className="stay"
              clickable
            />
          </Rows>
        </Wrapper>
        <Modal isOpen={emailModalOpen} onRequestClose={this.onModalClose} full={mobile}>
          <ApplicationMailing
            companyId={company.id}
            recruitmentId={recruitmentId}
            templates={emailTemplates}
            applications={selected}
            onClose={this.onModalClose}
            onUpdate={this.applicationMailingOnUpdate}
            onError={this.applicationMailingOnError}
          />
        </Modal>
        <Modal isOpen={bookingModalOpen} onRequestClose={this.onModalClose} full={mobile}>
          <ApplicationInterviews
            companyId={company.id}
            recruitmentId={recruitmentId}
            templates={emailTemplates}
            applications={selected}
            onClose={this.onModalClose}
            onUpdate={this.applicationInterviewsOnUpdate}
            onError={this.applicationInterviewsOnError}
          />
        </Modal>
        <Modal isOpen={shareModalOpen} onRequestClose={this.onModalClose} className="pa4" overflow full={mobile}>
          <ApplicationSharing recruitmentId={recruitmentId} title={title} company={company} applications={selected} onClose={this.onModalClose} />
        </Modal>
        <Modal isOpen={messageLogModalOpen} onRequestClose={this.onModalClose} full={mobile}>
          <ApplicationMessageLog applicationId={applicationId} onClose={this.onModalClose} />
        </Modal>

        <Modal isOpen={showConfirmOrderModal} onRequestClose={this.dismissOrderModal}>
          <ModalHeader>Beställ våra rekryteringstjänster</ModalHeader>
          <p>
            Våra rekryterare vill och kan hjälpa er i denna rekrytering!
            <br />
            <br />
            Ni har valt följande tjänster:
          </p>
          <AddonList>
            <li key="tempkey">{this.state.newAddon}</li>
          </AddonList>
          <TextArea width="500px" rows={8} className="mt3" placeholder="Plats för ett frivilligt meddelande." onChange={this.onOrderMessageChange} value={orderMessage} />
          <Nowrap>
            <CancelButton className="mr3" onClick={this.dismissOrderModal}>
              Avbryt
            </CancelButton>
            <PrimaryButton onClick={this.handleOrder}>Skicka</PrimaryButton>
          </Nowrap>
        </Modal>
        <Loader isOpen={loading} />
      </Content>
    )
  }
}
